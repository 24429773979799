<template>
  <div class="invest" id="invest">
    <Header />
    <div class="other__narrow__banner">
      <img src="../assets/pic/invest_banner.png" alt="" />
      <div class="other__narrow__banner__title">
        <div class="title_container">
          <h4>投資人專區 - {{pageTitle}}</h4>
        </div>
      </div>
    </div>
    
    <div class="other__ck__block">
      <div class="container">
        <!-- investNavBar -->
        <div
          class="invest-nav-wrapper owl-carousel"
          :class="{ invest_background: navBg}"
        >
          <router-link
            v-for="a in item.investLevel2"
            :to="{name:'Invest', params:{l1: nowInvestCategory,l2: a.id}}"
            :key="a.id"
            class="pn-ProductNav_Link item"
          >
            {{ a.title }}
          </router-link>
        </div>
        <!-- level4 -->
        <div class="finance-title-wrapper" v-for="a in levelThreeData" :key="a.id">
          <div class="finance-title">
            <p>{{a.title}}</p>
            <span class="arrow"></span>
          </div>
          <div class="show-data-area">
            <div class="invest-html-mode" v-if="JSON.parse(a.json).mode === 'html'">
              <!-- header -->
              <!-- <div class="invest-level5-header my-3 level_five_click">
                <p></p>
                <div class="invest-html-descript ">
                  詳細資料
                </div>
              </div> -->
              <!-- content -->
              <div>
                <div class="modal_button" v-html="a.content" data-toggle="modal" :data-target="'#modal'+a.id"></div>
              </div>
              <!-- modal -->
              <div class="modal fade w-80" :id="'modal'+a.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">{{a.title}}</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body" v-html="a.content">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="invest-pdf-mode" v-else-if="JSON.parse(a.json).mode === 'pdf'">
              <template v-if="a.file">
                <div class="invest-level5-header my-3 header-border-bottom">
                  <!-- //pdf title -->
                  <p></p>
                  <div class="invest-html-descript">
                    <a :href="'https://www.elevator.com.vn/upload/' + a.file" target="_blank">PDF下載</a>
                  </div>
                </div>
              </template>
            </div>
            <!-- level5 -->
            <div class="other__ck__finance__list" v-for="element in item.investLevel4.filter(item => item.parent_id === a.id)" :key="element.id">
              <!-- html -->
              <div v-if="JSON.parse(element.json).mode === 'html'">
                <!-- html-header -->
                <div class="invest-level5-header my-3 level_five_click">
                  <p class="">{{element.title}}</p>
                  <div class="invest-html-descript">
                    詳細資料
                  </div>
                </div>
                <!-- html-content -->
                <div class="level_five_content modal_button">
                  <div class="modal_button" v-html="element.content" data-toggle="modal" :data-target="'#modal'+element.id" ></div>
                </div>
                <!-- html-modal -->
                <div class="modal fade w-80" :id="'modal'+element.id" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{element.title}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" v-html="element.content">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- pdf -->
              <div v-else-if="JSON.parse(element.json).mode === 'pdf'">
                <div class="invest-level5-header">
                  <p>{{element.title}}</p>
                  <div class="invest-html-descript">
                    <a :href="'https://www.elevator.com.vn/upload/' + element.file" target="_blank">
                      PDF下載
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiInvestData } from "@/api/pages.js";
import Header from "./../components/Header";

export default {
  name: "Invest",
  components: {
    Header,
  },
  data() {
    return {
      navDatas: [],
      navItems: 1,
      navSwitch: false,
      navStartPosition: -1,
      navBg: false,
      show: true,
      showtwo: false,
      showthree: false,
      pageTitle:'',
      p: {},
      item: {},
      nowInvestCategory:'',
      nowInvestPage:'',
      levelThreeData: [],
      levelFourData:[],
      borderRadius: true
    };
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      location.reload();
    }
  },
  created() {
    console.log('created')
    this.hash = this.$route.hash;
    this.p = this.$route.params;
    this.navPositionHandler();
  },
  mounted: async function () {
    console.log("mounted");
    const target = `p${this.p.pdid}`
    document.querySelector('body').scrollIntoView({behavior: "smooth"})
    var interval = setInterval( () => {
    var tar =  document.querySelector(`#${target}`)
      // console.log(tar)
      if (tar) {
        clearInterval(interval)
        setTimeout(() => {
          document.querySelector(`#${target}`).scrollIntoView({behavior: "smooth"})
        }, 300);
      } 
    }, 100);
    const res = await apiInvestData(this.p.l1);
    this.item = res.data;
    console.log(this.item)
    this.pageTitle = this.item.investLevel2[0].parent_title
    this.NavBarHandler()
    this.levelthree()
    //owlCarousel ready
    setTimeout( () => {
      console.log('this.navItems:',this.navItems)
      console.log('this.navSwitch:',this.navSwitch)
      console.log('this.navStartPosition:',this.navStartPosition)
      $(".owl-carousel").owlCarousel({
        items: this.navItems,
        autoWidth: true,
        nav: this.navSwitch,
        URLhashListener: true,
        startPosition: `${this.navStartPosition}`,
      });
    },100)
    // $(document).ready(function() {
    //   console.log('this.navItems:',this.navItems)
    //   console.log('this.navSwitch:',this.navSwitch)
    //   console.log('this.navStartPosition:',this.navStartPosition)
    //   $(".owl-carousel").owlCarousel({
    //     items: this.navItems,
    //     autoWidth: true,
    //     nav: this.navSwitch,
    //     URLhashListener: true,
    //     startPosition: `${this.navStartPosition}`,
    //   });
    // })
            console.log($('.finance-title .arrow'))
    //slideUp and Down 這個
    $(document).ready(function() {
      $('.show-data-area').hide()
      $('.show-data-area:first').show()
      $('.arrow:first').addClass('arrow-rotate')
      $('.finance-title').click(function(){
        const target = $(this).next()
        const arrow = $(this).find('span')
        arrow.toggleClass('arrow-rotate')
        if(target.is(':visible')){
          target.slideUp()
        }else{
          target.slideDown()
        }
      })
      // level5 sildeUp Down
      $('.level_five_content').hide()
      // $('.show-data-area .level_five_content:first').show()
      $('.level_five_click').click(function(){
        const target = $(this).next()
        if(target.is(':visible')){
          target.slideUp()
        }else{
          target.slideDown()
        }
      })
    })
    setTimeout(()=>{
      $('.invest-nav-wrapper .owl-item:first').css({"border-top-left-radius":"5px","border-bottom-left-radius":"5px"})
      $('.invest-nav-wrapper .owl-item:last').css({"border-top-right-radius":"5px","border-bottom-right-radius":"5px"})
      
    },100)
  },
  methods: {
    investInfoHandler(e) {
      this.show = !this.show;
    },
    navPositionHandler() {
      const pageName = this.$route.path;
      const pageData = pageName.split('/')
      this.nowInvestCategory = pageData[2]
      this.nowInvestPage = pageData[3]
    },
    NavBarHandler(){
      const navLength = this.item.investLevel2.length
      const pageName = this.$route.path;
      const pageData = pageName.split('/')
      const navActive = this.item.investLevel2.findIndex(item => item.id === Number(pageData[3]))
      this.navStartPosition = navActive
      if(navLength >= 6){
        this.navSwitch = true
        this.navBg = true
      }else{
        
      }
    },
    levelthree(){
      const levelthree = this.item.investLevel3.filter(element => 
        element.parent_id === Number(this.nowInvestPage)
      )
      this.levelThreeData = levelthree
      console.log(this.levelThreeData)
    }
  },
};
</script>
<style lan="css" scoped>
.fade-transition {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave {
  opacity: 0;
}
.owl-item{
  background-color: red;
}
.owl-item:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.owl-item:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
a:hover {
  text-decoration: none;
  color: black;
}
.invest-level5-header{
  display: flex;
  justify-content:space-between;
  align-content: center;
  margin: 10px 0px;
}
.header-border-bottom{
  border-bottom: 1px dashed black;
}
.invest-level5-header p{
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 0px;
}
.other__ck__finance__list{
  padding: 10px 0px;
  border-bottom: 1px dashed black;
}
.finance-title:hover{
  cursor: pointer;
}
/* 左上圓角 border-top-left-radius
右上圓角 border-top-right-radius
左下圓角 border-bottom-left-radius
右下圓角 border-bottom-right-radius */

.navleftradius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.navrightradius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.modal-dialog{
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}
.invest-html-descript{
  cursor: pointer;
  white-space: nowrap;
}
.show-data-area a{
  color: black;
}
.level_five_click{
  cursor: pointer;
}
.modal_button{
  cursor: pointer;
}
.finance-title-wrapper{
  padding:5px 0;
}
.invest-html-mode{
  padding: 10px 0;
}
.invest-html-mode p{
  margin:0;
}
.modal_button p{
  margin:0;
}
.finance-title .arrow{
    width: 16px;
    height: 19px;
    margin-right: 5px;
    transform: rotate(0deg);
    transition: all .3s;
}
.finance-title .arrow::before{
  font-family: 'Glyphicons Halflings';
	content: "\e114";
}
.arrow-rotate{
  transform: rotate(180deg) !important
}
@media screen and (min-width:768px){
  .modal-dialog{
    max-width: 1440px;
    width: 80%;
    margin: 0 auto;
  }
  .invest-level5-header p{
    font-size: 20px;
    margin: 0px 0px 0px 16px;
  }
}
</style>
